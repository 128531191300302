<template>
<v-row>
  <v-col cols="12" md="6">
    <search-box
        label="* Comprador"
        popup-label="Selecione um Comprador"
        :id.sync="value.idComprador"
        :nomeCompleto.sync="value.descricaoComprador"
        :hidden-clear="true"
        :value="comprador"
        :loader-fn="loadeComprador"
        item-key="id"
        item-text="nomeCompleto"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Comprador encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="6">
    <search-box
        label="* Item Demandado"
        popup-label="Selecione uma Demanda"
        :id.sync="value.idOferta"
        :descricao.sync="value.descricaoOferta"
        :hidden-clear="true"
        :value="oferta"
        :loader-fn="loadeOferta"
        item-key="id"
        item-text="descricao"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Oferta encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="12">
    <v-textarea 
      dense
      rows="6"
      label="* Descrição da Demanda"
      :rules="[
          this.$validators.string.required,
          v => this.$validators.string.greaterThanOrEquals(v, 1),
          v => this.$validators.string.lessThanOrEquals(v, 4000),
      ]"
      v-model.trim="value.descricao"/>
  </v-col>
  <v-col cols="12" md="6">
    <v-text-field label="Data Expiração"
                  dense
                  type="date"
                  :rules="[
                      this.$validators.string.required,
                  ]"
                  v-model="value.dataExpiracao" />
  </v-col>
  <v-col cols="12" md="6">
      <lookup-dominio label="* Status"
                      :hidden-clear="false"
                      hint="Selecione o Status"
                      v-model="value.status"
                      :rules="[
                          this.$validators.string.required,
                      ]"
                      :type="lookups.status"
                      />
    </v-col>    
</v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import {buscaEnderecoViaCep} from '../../../api/correios';
import Vue from 'vue';
//PKs
import {findAll as findAllComprador} from "../../../api/comprador";
import {findAll as findAllOferta} from "../../../api/oferta";

export default {
  name: "DemandaForm",
  components: {SearchBox, LookupDominio},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO,
      },
      loading: false,
      error: null,
    }
  },
  computed: {
    comprador(){
      if (this.value.idComprador == null) return null;
      return {
        id: this.value.idComprador,
        nomeCompleto: this.value.descricaoComprador
      }      
    },
    oferta(){
      if (this.value.idOferta == null) return null;
      return {
        id: this.value.idOferta,
        descricao: this.value.descricaoOferta
      }      
    },
  },
  watch: {
  },
  methods: {
    loadeComprador(page, search) {
      return findAllComprador(page, {
        nome: search
      })
    },
    loadeOferta(page, search) {
      return findAllOferta(page, {
        descricao: search
      })
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>