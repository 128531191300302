var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('search-box',{attrs:{"label":"* Comprador","popup-label":"Selecione um Comprador","id":_vm.value.idComprador,"nomeCompleto":_vm.value.descricaoComprador,"hidden-clear":true,"value":_vm.comprador,"loader-fn":_vm.loadeComprador,"item-key":"id","item-text":"nomeCompleto","rules":[
            this.$validators.notNullOrUndefined
        ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idComprador", $event)},"update:nomeCompleto":function($event){return _vm.$set(_vm.value, "descricaoComprador", $event)},"update:nome-completo":function($event){return _vm.$set(_vm.value, "descricaoComprador", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Comprador encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('search-box',{attrs:{"label":"* Item Demandado","popup-label":"Selecione uma Demanda","id":_vm.value.idOferta,"descricao":_vm.value.descricaoOferta,"hidden-clear":true,"value":_vm.oferta,"loader-fn":_vm.loadeOferta,"item-key":"id","item-text":"descricao","rules":[
            this.$validators.notNullOrUndefined
        ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idOferta", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descricaoOferta", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Oferta encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"dense":"","rows":"6","label":"* Descrição da Demanda","rules":[
          this.$validators.string.required,
          function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
          function (v) { return this$1.$validators.string.lessThanOrEquals(v, 4000); } ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Data Expiração","dense":"","type":"date","rules":[
                      this.$validators.string.required ]},model:{value:(_vm.value.dataExpiracao),callback:function ($$v) {_vm.$set(_vm.value, "dataExpiracao", $$v)},expression:"value.dataExpiracao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('lookup-dominio',{attrs:{"label":"* Status","hidden-clear":false,"hint":"Selecione o Status","rules":[
                          this.$validators.string.required ],"type":_vm.lookups.status},model:{value:(_vm.value.status),callback:function ($$v) {_vm.$set(_vm.value, "status", $$v)},expression:"value.status"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }