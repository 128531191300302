<template>
  <base-view
      @click:add="criar"
      :permiteCadastrar="permiteCadastrar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditDemandaDialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <DemandaDataTable ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import DemandaDataTable from "../../components/shared/datatables/DemandaDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/demanda";
import CreateEditDemandaDialog from "../../components/shared/dialogs/CreateEditDemandaDialog";
import {ApiError} from "../../api/core";
import {mapActions, mapGetters} from "vuex";
import {findAll as findAllComprador} from '../../api/comprador';
import {findAll as findAllOferta} from '../../api/oferta';
import {ItemDeDominio} from "../../api/itemsDominio";
import * as moment from 'moment';

export default {
  name: "index",
  components: {CreateEditDemandaDialog, BaseView, DemandaDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      permiteCadastrar: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'ID',
          column: 'id',
          type: 'number',
          prefix: '#'
        },
        {
          name: 'Comprador',
          column: 'idComprador',
          type: 'autocomplete',
          optionsFactory: (search) => findAllComprador(1, {
            nomeFantasia: search,
            razaoSocial: search,
            cnpj: search,
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.nomeCompleto
                }))
              })

        },
        {
          name: 'Ofertas',
          column: 'idOferta',
          type: 'autocomplete',
          optionsFactory: (search) => findAllOferta(1, {
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        }, 
        {
          name: 'Descrição',
          column: 'descricao',
          type: 'text'
        },
        {
          group: 'Data Expiração',
          columns: [
            {
              name: 'De',
              column: 'dtInicio',
              type: 'date',
              defaultValue: moment().startOf('month').format('YYYY-MM-DD'),
            },
            {
              name: 'Até',
              column: 'dtFim',
              type: 'date',
              defaultValue: moment().endOf('month').format('YYYY-MM-DD'),
            },
          ]
        },        
        {
          name: 'Status',
          column: 'status',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.STATUS_PADRAO
          })
        },
      ]
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },  
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {
        id: null,
        idOferta: null,
        descricaoOferta: "",
        idComprador: null,
        descricaoComprador: "",
        descricao: "",
        dataCadastro: "",
        dataExpiracao: "",
        status: "A",
        descricaoStatus: "",
      };
      this.editDialogOpened = true;
    },
    editar(objeto) {
      this.item = JSON.parse(JSON.stringify(objeto));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Demanda removido com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {        
          this.$toast.error(`Não foi possível remover o Demanda.`, {
            timeout: 6000
          })
        }  
      }
    },
	  async getPermissoes(){
      this.permiteCadastrar = await this.getPermissaoPorItemPerfil("Demanda.cadastro",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>